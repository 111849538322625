import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../sections/ContactForm';
import Social from '../components/Social';

const ContactUsPage = () => {
  return (
    <Layout>
      <div className={'vh-100 d-flex flex-column'}>
        <Header alwaysVisible/>

        <div className="vh-100 navbar-height-margin container">
          <div className="row justify-content-center">
            <div className="col-4 align-items-center">
              <h1 align="center">Contact Us</h1>
              <p>
                Contact us about anything related to our company or service.
                We'll do our best to get back to you as soon as possible.
              </p>
              <Social innerClassName={'fa-2x m-2'} />
            </div>
            <div className="col-8">
              <ContactForm />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default ContactUsPage;
